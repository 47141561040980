import BaseBean from "@/utils/BaseBean";

export interface IOrderDetailDataObj {
    utilInst:OrderDetailUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class OrderDetailUtil extends BaseBean{
    public dataObj:IOrderDetailDataObj;

    constructor(proxy:any,dataObj:IOrderDetailDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{

    }
}