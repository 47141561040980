import {reactive, toRefs,defineComponent,onBeforeMount,onMounted,getCurrentInstance,nextTick} from 'vue';
import OrderDetailUtil ,{IOrderDetailDataObj} from './orderDetailUtil';
import html2pdf from 'html2pdf.js';
// import FileSaver from 'file-saver';
export default defineComponent({
    name:'PiTemplateCard',
    title:'订单详情',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IOrderDetailDataObj=reactive<IOrderDetailDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/order')
            },
            //表单
            form:{
            },
            //表单验证规则
            rules: {},
            otherParams:{
                cfgData:[],//订单配置总信息
                processInfo:{},//审核结果信息
                customer:{//客户信息
                    customerId:{},
                    customerDeliveryId:{
                        item:{}
                    }
                },
                orderData:[],//处理之后的订单配置信息
                sumNum:0,//总数量
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new OrderDetailUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            let sumNum=0;
            let jsonContent=JSON.parse(res.data.jsonContent);
            dataObj.otherParams.cfgData=jsonContent.cfgData;
            dataObj.otherParams.processInfo=jsonContent.processInfo;
            dataObj.otherParams.customer=jsonContent.customer;
            dataObj.otherParams.cfgData.forEach((item1:any)=>{
                item1.itemChildren.forEach((item2:any)=>{
                    let cxName=item2.header.cxName;
                    let cxggh=item2.header.cxggh;
                    let enDesc=item2.header.enDesc;
                    let zhDesc=item2.header.zhDesc;
                    let totalNum=0;
                    let obj={cxName:cxName,cxggh:cxggh,enDesc:enDesc,zhDesc:zhDesc,color:'-',num:'TOTAL '+totalNum};
                    dataObj.otherParams.orderData.push(obj)
                    item2.children.forEach((item3:any)=>{
                        let color=item3.color;
                        let num=item3.num;
                        totalNum+=item3.num;
                        sumNum+=item3.num;
                        dataObj.otherParams.orderData.push({cxName:'',cxggh:'',enDesc:'',color:color,num:num})
                    });
                    obj.num='TOTAL '+totalNum;
                })
            })
            dataObj.otherParams.sumNum=sumNum;
        }

        const setParam=(inst:any)=>{
            //from===PiCard PiTemplateList
            return{code:inst.engineParams.code,templateId:inst.engineParams.templateId,from:inst.engineParams.from,id:inst.engineParams.id,showButtons:false};
        }

        const printPdf=()=>{
            let dialogContent:any = (document.getElementById('orderDetailContent') as any).innerHTML;
            let printWindow :any= window.open('', '_blank');
            printWindow.document.open();
            printWindow.document.write("<html><head><title>"+dataObj.form.code+"</title></head><body>");
            printWindow.document.write(dialogContent);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        }
        const downloadPdf=()=>{
            const element = dataObj.refMap.get('orderDetailContent'); // 通过 ref 获取要转换为 PDF 的元素
            (html2pdf as any)().from(element).save(dataObj.form.code+'.pdf'); // 将元素转换为 PDF 并保存为文件
        }
        return{
            ...toRefs(dataObj),proxy,beforeOpen,setParam,printPdf,downloadPdf
        }
    }
});